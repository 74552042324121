import { SVGIconComponent } from '../types';

export const blik: SVGIconComponent = ({ title = '', ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    {...props}
  >
    <title>{title}</title>
    <g>
      <circle cx="12" cy="12" r="12" fill={'#000000'} />
      <g>
        <circle className="st0" fill={'#F41142'} cx="14.242" cy="7.817" r="2.001" />
        <path
          className="st1"
          fill={'#FFFFFF'}
          d="M12,10.754c-0.7-0.001-1.39,0.172-2.006,0.503V6.458H7.757v8.535c-0.002,2.343,1.896,4.244,4.239,4.246
			c2.343,0.002,4.244-1.896,4.246-4.239c0.002-2.343-1.896-4.244-4.239-4.246C12.002,10.754,12.001,10.754,12,10.754z M12,17.037
			c-1.128,0-2.042-0.914-2.042-2.042c0-1.128,0.914-2.042,2.042-2.042c1.128,0,2.042,0.914,2.042,2.042
			C14.042,16.122,13.128,17.036,12,17.037L12,17.037L12,17.037z"
        />
      </g>
    </g>
  </svg>
);
