import { SVGIconComponent } from '../types';

export const edenred: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-dir="ltr" viewBox="0 0 32 32" {...props}>
    <title>{title}</title>
    <path
      d="M30 5H2C0.89543 5 0 5.95371 0 7.13016V24.8698C0 26.0463 0.89543 27 2 27H30C31.1046 27 32 26.0463 32 24.8698V7.13016C32 5.95371 31.1046 5 30 5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5.53254C1.17157 5.53254 0.5 6.24782 0.5 7.13016V24.8698C0.5 25.7522 1.17157 26.4675 2 26.4675H30C30.8284 26.4675 31.5 25.7522 31.5 24.8698V7.13016C31.5 6.24782 30.8284 5.53254 30 5.53254H2ZM0 7.13016C0 5.95371 0.895431 5 2 5H30C31.1046 5 32 5.95371 32 7.13016V24.8698C32 26.0463 31.1046 27 30 27H2C0.895431 27 0 26.0463 0 24.8698V7.13016Z"
      fill="black"
      fillOpacity="0.3"
    />
    <path d="M12.8703 14.9506C12.4102 14.9506 12.1182 15.2501 12.0274 15.6472H13.6644C13.6089 15.2088 13.3019 14.9506 12.8703 14.9506Z" />
    <path d="M8.49523 15.1248C7.9098 15.1248 7.54053 15.5286 7.54053 16.093C7.54053 16.6507 7.91658 17.068 8.49523 17.068C9.07387 17.068 9.46348 16.671 9.46348 16.093C9.46348 15.5151 9.0732 15.1248 8.49523 15.1248Z" />
    <path d="M20.3094 14.1985C19.4306 14.1985 18.7462 14.5495 18.6344 15.4609C18.6263 15.4615 18.6398 15.4609 18.6317 15.4609C18.3552 11.331 15.0975 8.06583 10.8979 8.06583C7.57914 8.06583 4.73808 10.106 3.55639 12.9999H6.37035L6.22399 13.9472H4.01578V14.9506H6.0153L5.86217 15.8633H4.01578V16.4488C4.01578 16.8038 4.16891 16.9712 4.46163 16.9712H6.34934L6.19621 17.9184H4.1059C4.00426 17.9184 3.90737 17.9089 3.81522 17.8927V17.8933C3.81522 17.8933 3.4534 17.854 3.22912 17.5627C3.2237 17.5559 3.13223 17.4475 3.08751 17.2693C3.07599 17.2225 3.07463 17.2198 3.06583 17.1832C3.08073 17.3011 3.1058 17.4597 3.11732 17.518C3.18101 17.8486 3.26164 18.1285 3.2691 18.1481C4.20821 21.479 7.26678 23.9217 10.8979 23.9217C14.6123 23.9217 17.7502 21.3666 18.61 17.9191H17.5442V15.9826C17.5442 15.4602 17.3423 15.1255 16.8266 15.1255C16.311 15.1255 16.1091 15.4602 16.1091 15.9826V17.9191H15.0433V15.7177C15.0433 14.6654 15.8096 14.1992 16.8266 14.1992C17.8437 14.1992 18.61 14.6661 18.61 15.7177L18.6093 17.9191H19.6819V15.9826C19.6819 15.4602 19.8215 15.1255 20.3297 15.1255C20.4903 15.1255 20.6847 15.1742 20.7545 15.2088L20.9564 14.2751C20.8392 14.2609 20.5397 14.1985 20.3094 14.1985ZM10.5361 15.8776C10.5361 17.2083 9.74872 17.9882 8.44575 17.9882C7.24035 17.9882 6.42523 17.1799 6.42523 16.093C6.42523 14.9574 7.19834 14.1979 8.35496 14.1979C8.88414 14.1979 9.27442 14.4065 9.46956 14.6159V12.9999H10.5354V15.8776H10.5361ZM13.2253 17.1873C13.5391 17.1873 13.8291 17.0965 14.1014 16.9712L14.0127 17.8141C13.7064 17.9394 13.3371 17.9882 12.9679 17.9882C11.7415 17.9882 10.8776 17.2706 10.8776 16.0517C10.8776 14.9926 11.6507 14.1985 12.8351 14.1985C14.1726 14.1985 14.8414 15.0482 14.8136 16.358H12.0552C12.1595 16.9576 12.7307 17.1873 13.2253 17.1873Z" />
    <path d="M22.7269 14.1985C21.5425 14.1985 20.7694 14.9926 20.7694 16.0517C20.7694 17.2706 21.6333 17.9882 22.8597 17.9882C23.229 17.9882 23.5983 17.9394 23.9046 17.8141L23.9933 16.9712C23.7216 17.0965 23.4309 17.1873 23.1179 17.1873C22.6233 17.1873 22.0521 16.9576 21.9477 16.358H24.7068C24.7332 15.0482 24.0645 14.1985 22.7269 14.1985ZM21.9186 15.6479C22.0094 15.2508 22.3021 14.9513 22.7615 14.9513C23.1938 14.9513 23.5001 15.2088 23.5556 15.6479H21.9186Z" />
    <path d="M27.8684 12.9999V14.6159C27.6732 14.4072 27.2829 14.1979 26.7538 14.1979C25.5971 14.1979 24.824 14.9574 24.824 16.093C24.824 17.1799 25.6392 17.9882 26.8446 17.9882C28.1475 17.9882 28.9349 17.2076 28.9349 15.8776V12.9999H27.8684ZM26.8927 17.0687C26.3147 17.0687 25.938 16.6507 25.938 16.0937C25.938 15.5293 26.3072 15.1255 26.8927 15.1255C27.4706 15.1255 27.8609 15.5157 27.8609 16.0937C27.8616 16.6717 27.4713 17.0687 26.8927 17.0687Z" />
  </svg>
);
