import { IIcon } from '../types/icon';

export const commonIcons: IIcon = {
  account: 'account',
  add: 'add',
  amex: 'amex',
  applepay: 'applepay',
  arrowUp: 'arrowUp',
  back: 'back',
  bkCrown: 'noop',
  blik: 'blik',
  cart: 'cart',
  cateringBirthday: 'cateringBirthday',
  cateringTwo: 'cateringTwo',
  check: 'check',
  checkCircle: 'checkCircle',
  close: 'close',
  communicationPref: 'communicationPref',
  curbside: 'curbside',
  deleteAccount: 'deleteAccount',
  delivery: 'delivery',
  dineIn: 'dineIn',
  discover: 'discover',
  driveThru: 'driveThru',
  edit: 'edit',
  editWithEraser: 'editWithEraser',
  edenred: 'edenred',
  error: 'error',
  favorite: 'favorite',
  favoriteFill: 'favoriteFill',
  fhsRewards: 'noop',
  gear: 'gear',
  googlePay: 'googlePay',
  help: 'help',
  halal: 'halal',
  info: 'info',
  instagram: 'instagram',
  invitationCode: 'invitationCode',
  language: 'language',
  list: 'list',
  listAlt: 'listAlt',
  location: 'location',
  lock: 'lock',
  map: 'map',
  mastercard: 'mastercard',
  mbway: 'mbway',
  menu: 'menu',
  mobile: 'mobile',
  next: 'next',
  offer: 'offer',
  order: 'noop',
  paypal: 'paypal',
  paymentCard: 'paymentCard',
  paymentCash: 'paymentCash',
  plkRewards: 'noop',
  preferences: 'preferences',
  previous: 'previous',
  profile: 'profile',
  refresh: 'refresh',
  remove: 'remove',
  requestInfo: 'requestInfo',
  restaurant: 'restaurant',
  rewards: 'noop',
  sadFace: 'sadFace',
  search: 'search',
  select: 'select',
  settings: 'settings',
  ticketRestaurant: 'ticketRestaurant',
  time: 'time',
  visa: 'visa',
  warning: 'warning',
  errorFilled: 'errorFilled',
  locationOff: 'locationOff',
  motorcycleHelmet: 'motorcycleHelmet',
  nightlight: 'nightlight',
  thunderstorm: 'thunderstorm',
  traffic: 'traffic',
  people: 'people',
};
