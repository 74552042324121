import { SVGIconComponent } from '../types';

export const ticketRestaurant: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-dir="ltr" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <title>{title}</title>
    <path
      d="M30 5H2C0.89543 5 0 5.95371 0 7.13016V24.8698C0 26.0463 0.89543 27 2 27H30C31.1046 27 32 26.0463 32 24.8698V7.13016C32 5.95371 31.1046 5 30 5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5.53254C1.17157 5.53254 0.5 6.24782 0.5 7.13016V24.8698C0.5 25.7522 1.17157 26.4675 2 26.4675H30C30.8284 26.4675 31.5 25.7522 31.5 24.8698V7.13016C31.5 6.24782 30.8284 5.53254 30 5.53254H2ZM0 7.13016C0 5.95371 0.895431 5 2 5H30C31.1046 5 32 5.95371 32 7.13016V24.8698C32 26.0463 31.1046 27 30 27H2C0.895431 27 0 26.0463 0 24.8698V7.13016Z"
      fill="black"
      fillOpacity="0.3"
    />

    <g clipPath="url(#clip0_2230_59)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.05023 15.4607L8.87817 15.5353V16.9651H6.32397V18.873H4.70382V16.937H2.05023V15.4607ZM2.26397 15.6789V16.7212H4.91756V18.6572H6.11023V16.7493H8.66443V15.7488L2.26397 15.6789Z"
        fill="#2C5FA1"
      />
      <path
        d="M3.73237 17.168V20.2565L3.43206 20.3428L3.35512 20.4896H5.14626V19.8183L5.97878 19.8324L6.41268 20.4896H8.11298L7.21741 19.7471C7.21741 19.7471 8.58962 18.505 7.21741 17.1701H6.63069L6.62214 19.0586L4.47726 19.0457L4.47405 17.1669H3.73237V17.168Z"
        fill="#2C5FA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.35252 18.3561V17.168H5.67313V18.3561H5.35252Z"
        fill="#2C5FA1"
      />
      <path
        d="M2.58351 12.15C2.59206 10.4655 3.96962 9.04641 5.59832 9.04317C7.23664 9.03994 8.65481 10.5173 8.62809 12.1986C8.60138 13.9004 7.24306 15.2558 5.57588 15.2439C3.9055 15.232 2.57496 13.8561 2.58351 12.15Z"
        fill="#E02928"
      />
      <path
        d="M21.5434 20.3719L20.1679 20.3611C20.1519 20.3611 20.1369 20.3525 20.1284 20.3385C20.0525 20.2101 19.6689 19.554 19.4017 18.873L19.2713 18.8989C19.2713 19.2647 19.2553 19.6306 19.2756 19.9953C19.2927 20.2888 19.2029 20.3924 18.893 20.3978C17.9568 20.414 17.9568 20.4291 17.9568 19.4881C17.9568 18.3302 17.976 17.1723 17.9461 16.0144C17.9354 15.6043 17.9942 15.532 18.4527 15.5234H18.8235C19.2296 15.5104 19.2895 15.5968 19.2777 15.9593C19.2521 16.7417 19.2692 17.5252 19.2692 18.3809C19.5032 18.191 20.0354 17.2468 20.1423 17.0558C20.154 17.0342 20.1765 17.0223 20.2 17.0223H21.3852C21.4108 17.0223 21.4269 17.0514 21.4119 17.073C21.1554 17.4432 20.9128 17.8964 20.6456 18.2406C20.4907 18.4414 20.4875 18.5978 20.6328 18.8093C20.9545 19.2766 21.2516 19.8356 21.5658 20.3288C21.5776 20.3482 21.5647 20.373 21.5412 20.373L21.5434 20.3719Z"
        fill="#2C5FA1"
      />
      <path
        d="M23.544 20.4626C22.0328 20.4658 21.2206 19.4558 21.5957 18.1295C21.8127 17.3601 22.5725 16.8961 23.4724 16.9835C24.3904 17.072 24.9205 17.6029 24.9824 18.4748C25.0006 18.7263 24.9589 18.8698 24.6608 18.8914C24.1852 18.927 23.7214 18.9745 23.2447 18.9691C23.1047 18.9669 22.8707 19.0068 22.8557 19.2399C22.844 19.4353 23.0182 19.5561 23.1956 19.6025C23.545 19.6932 23.9148 19.6921 24.245 19.5745C24.6757 19.4202 24.6714 19.7568 24.7623 19.9727C24.9076 20.3169 24.6105 20.3288 24.4342 20.3741C24.1264 20.4529 23.8753 20.4712 23.5429 20.4637L23.544 20.4626ZM23.6551 18.1694C23.6765 17.8845 23.5953 17.6849 23.2554 17.6806C22.8365 17.6763 22.7232 17.8586 22.7446 18.1997C22.766 18.5407 23.6316 18.4651 23.6551 18.1694Z"
        fill="#2C5FA1"
      />
      <path
        d="M25.5382 18.8018C25.5382 18.5709 25.5382 18.3389 25.5382 18.1079C25.5382 17.9633 25.535 17.7961 25.3405 17.7982C25.0444 17.8014 24.9856 17.0428 25.3618 17.0558C25.6076 17.0644 25.5435 16.9608 25.5392 16.8421C25.5328 16.6781 25.5061 16.4234 25.645 16.3597C25.9795 16.2043 26.3621 16.1331 26.7266 16.1148C26.9895 16.1018 26.8602 16.3878 26.874 16.541C26.8976 16.7957 26.7533 17.0795 27.2032 17.0644C27.5463 17.0525 27.5719 17.7831 27.1979 17.795C26.9232 17.8036 26.8687 17.9331 26.8751 18.1662C26.8869 18.5396 26.8773 18.914 26.8805 19.2874C26.8815 19.4827 26.9318 19.6576 27.1722 19.6414C27.2427 19.636 27.4169 19.6435 27.4169 19.6435C27.4169 19.6435 27.4159 19.8615 27.4244 19.9932C27.4383 20.2079 27.4009 20.3579 27.154 20.4313C26.2734 20.6914 25.5456 20.1583 25.5382 19.2302C25.5371 19.0878 25.5382 18.9453 25.5382 18.8029V18.8018Z"
        fill="#2C5FA1"
      />
      <path
        d="M11.0466 18.8007C11.0466 18.587 11.0487 18.3734 11.0466 18.1586C11.0444 18.0032 11.0637 17.8759 10.8403 17.7993C10.4513 17.6665 10.6298 17.1442 10.7078 17.1237C11.069 17.0288 11.0615 16.8032 11.0519 16.5324C11.0466 16.3748 11.1556 16.3263 11.2902 16.3025C11.4473 16.2745 11.6087 16.2518 11.7551 16.1957C12.22 16.0165 12.4444 16.1191 12.3632 16.6629C12.3343 16.8583 12.3301 17.0741 12.6389 17.0482C12.9638 17.0212 12.8954 17.2651 12.8986 17.4475C12.9008 17.618 12.9478 17.8252 12.6582 17.8144C12.5417 17.8101 12.3739 17.7885 12.3718 17.9676C12.3664 18.4651 12.376 18.9626 12.3963 19.4601C12.4006 19.5755 12.5139 19.6468 12.6282 19.6403C12.9991 19.6176 12.8772 19.8917 12.9018 20.0795C12.9371 20.3482 12.7586 20.4065 12.5534 20.4475C11.6589 20.6234 11.0551 20.1356 11.0487 19.2291C11.0487 19.0867 11.0487 18.9442 11.0487 18.8018L11.0466 18.8007Z"
        fill="#2C5FA1"
      />
      <path
        d="M16.7769 20.4701C16.0182 20.4486 15.4913 20.1917 15.2102 19.5442C14.911 18.8536 15.0424 17.9169 15.5127 17.4453C16.0192 16.9371 16.6348 16.8982 17.2846 17.0752C17.4289 17.114 17.634 17.1723 17.5347 17.3914C17.4598 17.5576 17.4962 17.8112 17.1595 17.8068C16.5557 17.7982 16.3847 18.0302 16.3911 18.7155C16.3976 19.3781 16.5856 19.6327 17.1435 19.5928C17.524 19.5658 17.462 19.8626 17.5411 20.0363C17.6501 20.277 17.4342 20.3353 17.2621 20.3784C17.0751 20.4248 16.8827 20.4507 16.7748 20.4701H16.7769Z"
        fill="#2C5FA1"
      />
      <path
        d="M13.3026 18.7047C13.3026 18.2784 13.3208 17.8511 13.2962 17.4259C13.278 17.1119 13.4009 17.0439 13.6916 17.0396C14.6449 17.0255 14.6449 17.0137 14.6449 17.9838C14.6449 18.6237 14.6278 19.2637 14.6513 19.9025C14.6641 20.2522 14.5904 20.4054 14.195 20.4C13.3026 20.386 13.3026 20.4076 13.3026 19.5043C13.3026 19.2378 13.3026 18.9712 13.3026 18.7047Z"
        fill="#2C5FA1"
      />
      <path
        d="M14.7956 15.8741C14.7892 16.3187 14.4258 16.6899 13.9855 16.6996C13.5345 16.7104 13.1359 16.3068 13.1434 15.8482C13.1508 15.4047 13.5163 15.0356 13.9577 15.027C14.4194 15.0183 14.802 15.4047 14.7956 15.873V15.8741Z"
        fill="#2C5FA1"
      />
      <path
        d="M11.815 21.0626C12.0447 21.0626 12.329 21.0917 12.4797 21.1489C12.6304 21.2061 12.7426 21.2935 12.8163 21.4112C12.8901 21.5288 12.9275 21.6788 12.9275 21.8612C12.9275 22.0112 12.9008 22.1363 12.8473 22.2356C12.7939 22.336 12.7234 22.4169 12.6357 22.4784C12.5492 22.541 12.3621 22.5885 12.2638 22.6219L13.0333 23.8953H12.6539L11.9667 22.7137H11.3832V23.8953H11.0573V21.0626H11.815ZM11.7957 21.3475H11.3832V22.4342H11.8289C12.0576 22.4342 12.3194 22.3867 12.4284 22.2928C12.5363 22.1989 12.5908 22.0597 12.5908 21.8763C12.5908 21.6842 12.5331 21.5471 12.4188 21.4673C12.3044 21.3874 12.034 21.3475 11.7968 21.3475H11.7957Z"
        fill="#2C5FA1"
      />
      <path
        d="M14.1576 21.695C14.3371 21.695 14.4899 21.7349 14.6182 21.8147C14.7464 21.8946 14.8437 22.0068 14.9121 22.1514C14.9794 22.295 15.0136 22.4633 15.0136 22.6565V22.8561H13.5612C13.5655 23.1054 13.6264 23.2953 13.745 23.4259C13.8637 23.5565 14.0314 23.6212 14.2473 23.6212C14.3798 23.6212 14.4985 23.6093 14.6 23.5845C14.7015 23.5597 14.8084 23.5241 14.9185 23.4755V23.7583C14.8127 23.8058 14.7069 23.8414 14.6032 23.8629C14.4985 23.8845 14.3756 23.8964 14.2334 23.8964C14.0314 23.8964 13.8551 23.8543 13.7044 23.7712C13.5537 23.6881 13.4372 23.5651 13.3539 23.4043C13.2705 23.2424 13.2289 23.045 13.2289 22.8119C13.2289 22.5788 13.2673 22.3856 13.3432 22.2183C13.4191 22.0511 13.527 21.9227 13.666 21.832C13.8049 21.7414 13.9684 21.696 14.1565 21.696L14.1576 21.695ZM14.1543 21.9583C13.9844 21.9583 13.8487 22.0144 13.7472 22.1266C13.6456 22.2388 13.5858 22.3964 13.5666 22.5971H14.6791C14.678 22.4709 14.6588 22.3586 14.6203 22.2626C14.5818 22.1665 14.5252 22.091 14.4482 22.0381C14.3713 21.9842 14.273 21.9572 14.1533 21.9572L14.1543 21.9583Z"
        fill="#2C5FA1"
      />
      <path
        d="M16.7107 23.2759C16.7107 23.4119 16.6776 23.5252 16.6102 23.618C16.5429 23.7108 16.4467 23.7799 16.3227 23.8263C16.1988 23.8727 16.0492 23.8964 15.8771 23.8964C15.7296 23.8964 15.6024 23.8845 15.4956 23.8619C15.3876 23.8381 15.2936 23.8058 15.2134 23.7626V23.4658C15.2989 23.509 15.4015 23.5468 15.5201 23.5824C15.6388 23.618 15.7606 23.6353 15.8846 23.6353C16.0663 23.6353 16.1977 23.605 16.28 23.5457C16.3623 23.4863 16.4029 23.4054 16.4029 23.304C16.4029 23.2457 16.3869 23.195 16.3537 23.1496C16.3206 23.1054 16.2661 23.0622 16.1892 23.0201C16.1122 22.9781 16.0064 22.9317 15.8707 22.882C15.736 22.8302 15.6206 22.7784 15.5223 22.7277C15.424 22.677 15.3492 22.6144 15.2957 22.541C15.2423 22.4676 15.2156 22.3716 15.2156 22.254C15.2156 22.0748 15.2872 21.9367 15.4314 21.8407C15.5757 21.7446 15.7649 21.696 15.9989 21.696C16.125 21.696 16.2437 21.709 16.3548 21.7338C16.466 21.7586 16.5685 21.7932 16.6647 21.8353L16.5568 22.0932C16.4702 22.0554 16.3772 22.0241 16.2789 21.9982C16.1806 21.9723 16.0801 21.9594 15.9776 21.9594C15.8301 21.9594 15.7179 21.9842 15.6388 22.0327C15.5597 22.0824 15.5212 22.1493 15.5212 22.2345C15.5212 22.3004 15.5394 22.3543 15.5757 22.3975C15.6121 22.4406 15.6708 22.4806 15.751 22.5173C15.8311 22.555 15.938 22.5982 16.0695 22.6489C16.2009 22.6986 16.3153 22.7482 16.4114 22.8011C16.5076 22.8529 16.5814 22.9165 16.6327 22.991C16.685 23.0655 16.7107 23.1604 16.7107 23.277V23.2759Z"
        fill="#2C5FA1"
      />
      <path
        d="M28.3895 23.632C28.4418 23.632 28.4953 23.6277 28.5508 23.6191C28.6053 23.6104 28.6502 23.5986 28.6855 23.5856V23.836C28.6481 23.8532 28.5968 23.8673 28.5305 23.8781C28.4643 23.8899 28.4002 23.8953 28.3382 23.8953C28.227 23.8953 28.1255 23.8759 28.0347 23.836C27.9438 23.796 27.8711 23.7302 27.8166 23.6353C27.7621 23.5403 27.7354 23.4108 27.7354 23.2435V22.1806H27.4362V21.8935L27.7376 21.8892L27.7343 21.2406H28.056V21.9205H28.6716V22.1806H28.056V23.2338C28.056 23.3665 28.086 23.4669 28.1469 23.5327C28.2078 23.5986 28.289 23.632 28.3895 23.632Z"
        fill="#2C5FA1"
      />
      <path
        d="M17.7559 23.632C17.8082 23.632 17.8617 23.6277 17.9173 23.6191C17.9718 23.6104 18.0166 23.5986 18.0519 23.5856V23.836C18.0145 23.8532 17.9632 23.8673 17.8969 23.8781C17.8307 23.8899 17.7666 23.8953 17.7046 23.8953C17.5934 23.8953 17.4919 23.8759 17.4011 23.836C17.3102 23.796 17.2376 23.7302 17.1831 23.6353C17.1286 23.5403 17.1018 23.4108 17.1018 23.2435V22.1806H16.8026V21.8935L17.104 21.8892L17.1008 21.2406H17.4224V21.9205H18.038V22.1806H17.4224V23.2338C17.4224 23.3665 17.4524 23.4669 17.5133 23.5327C17.5742 23.5986 17.6554 23.632 17.7559 23.632Z"
        fill="#2C5FA1"
      />
      <path
        d="M19.1238 21.6982C19.375 21.6982 19.5609 21.7554 19.6817 21.8687C19.8035 21.982 19.8644 22.1644 19.8644 22.4137V23.8575H19.6325L19.5705 23.5435H19.5556C19.4968 23.6212 19.4359 23.686 19.3718 23.7378C19.3076 23.7906 19.2339 23.8295 19.1505 23.8565C19.0672 23.8834 18.9635 23.8964 18.8427 23.8964C18.7145 23.8964 18.6012 23.8737 18.5018 23.8284C18.4024 23.7831 18.3244 23.7151 18.2678 23.6234C18.2111 23.5316 18.1823 23.4151 18.1823 23.2748C18.1823 23.0633 18.2656 22.9004 18.4313 22.787C18.598 22.6737 18.8513 22.6111 19.1911 22.6014L19.5534 22.5863V22.4568C19.5534 22.2734 19.5139 22.145 19.4359 22.0716C19.3579 21.9982 19.2478 21.9615 19.1056 21.9615C18.9956 21.9615 18.8908 21.9777 18.7914 22.0101C18.6921 22.0424 18.5969 22.0802 18.5072 22.1245L18.4089 21.8806C18.504 21.8309 18.6119 21.7899 18.7348 21.7543C18.8577 21.7198 18.987 21.7025 19.1227 21.7025L19.1238 21.6982ZM19.5502 22.8108L19.2296 22.8248C18.9678 22.8356 18.7829 22.8788 18.675 22.9543C18.5681 23.0309 18.5136 23.1388 18.5136 23.2781C18.5136 23.3989 18.5499 23.4896 18.6226 23.5478C18.6953 23.6061 18.7925 23.6352 18.9122 23.6352C19.0992 23.6352 19.2521 23.5824 19.3707 23.4777C19.4893 23.373 19.5492 23.2165 19.5492 23.0072V22.8119L19.5502 22.8108Z"
        fill="#2C5FA1"
      />
      <path
        d="M24.5453 21.6982C24.7965 21.6982 24.9824 21.7554 25.1032 21.8687C25.225 21.982 25.286 22.1644 25.286 22.4137V23.8575H25.054L24.9921 23.5435H24.9771C24.9183 23.6212 24.8574 23.686 24.7933 23.7378C24.7292 23.7906 24.6554 23.8295 24.5721 23.8565C24.4887 23.8834 24.385 23.8964 24.2643 23.8964C24.136 23.8964 24.0227 23.8737 23.9234 23.8284C23.824 23.7831 23.746 23.7151 23.6893 23.6234C23.6327 23.5316 23.6038 23.4151 23.6038 23.2748C23.6038 23.0633 23.6872 22.9004 23.8528 22.787C24.0195 22.6737 24.2728 22.6111 24.6127 22.6014L24.975 22.5863V22.4568C24.975 22.2734 24.9354 22.145 24.8574 22.0716C24.7794 21.9982 24.6693 21.9615 24.5272 21.9615C24.4171 21.9615 24.3124 21.9777 24.213 22.0101C24.1136 22.0424 24.0185 22.0802 23.9287 22.1245L23.8304 21.8806C23.9255 21.8309 24.0334 21.7899 24.1563 21.7543C24.2792 21.7198 24.4085 21.7025 24.5443 21.7025L24.5453 21.6982ZM24.9718 22.8108L24.6511 22.8248C24.3893 22.8356 24.2044 22.8788 24.0965 22.9543C23.9896 23.0309 23.9351 23.1388 23.9351 23.2781C23.9351 23.3989 23.9714 23.4896 24.0441 23.5478C24.1168 23.6061 24.214 23.6352 24.3337 23.6352C24.5208 23.6352 24.6736 23.5824 24.7922 23.4777C24.9108 23.373 24.9707 23.2165 24.9707 23.0072V22.8119L24.9718 22.8108Z"
        fill="#2C5FA1"
      />
      <path
        d="M21.9965 21.7338V23.8576H21.7357L21.6898 23.5597H21.6727C21.6289 23.6331 21.5733 23.6957 21.5038 23.7453C21.4344 23.795 21.3574 23.8338 21.2708 23.8586C21.1843 23.8835 21.0924 23.8964 20.9951 23.8964C20.8284 23.8964 20.6895 23.8694 20.5773 23.8155C20.465 23.7615 20.3795 23.6773 20.3229 23.564C20.2663 23.4507 20.2374 23.304 20.2374 23.1259V21.7349H20.5602V23.1032C20.5602 23.2802 20.5997 23.4119 20.6788 23.4993C20.7579 23.5867 20.8786 23.6299 21.0411 23.6299C21.2035 23.6299 21.3211 23.5996 21.414 23.5403C21.507 23.4809 21.5733 23.3935 21.615 23.2781C21.6556 23.1626 21.6759 23.0201 21.6759 22.8529V21.7349H21.9965V21.7338Z"
        fill="#2C5FA1"
      />
      <path
        d="M23.1496 21.7338C23.1924 21.7338 23.2362 21.736 23.2821 21.7403C23.3281 21.7446 23.3687 21.7511 23.405 21.7586L23.3644 22.0565C23.3302 22.0479 23.2918 22.0403 23.249 22.0349C23.2073 22.0295 23.1667 22.0273 23.1304 22.0273C23.046 22.0273 22.9658 22.0446 22.891 22.0791C22.8162 22.1137 22.751 22.1622 22.6944 22.2259C22.6377 22.2896 22.5939 22.3662 22.5618 22.4568C22.5298 22.5464 22.5137 22.6468 22.5137 22.7558V23.8953H22.1931V21.7716H22.4582L22.4924 22.1633H22.5052C22.549 22.0845 22.6014 22.0122 22.6623 21.9475C22.7232 21.8827 22.7948 21.8299 22.876 21.791C22.9573 21.7522 23.0481 21.7327 23.1475 21.7327L23.1496 21.7338Z"
        fill="#2C5FA1"
      />
      <path
        d="M26.4808 21.7338C26.7298 21.7338 26.9189 21.7953 27.0472 21.9183C27.1754 22.0414 27.2395 22.2399 27.2395 22.5119V23.8953H26.9253V22.5335C26.9253 22.3565 26.8847 22.2237 26.8056 22.1363C26.7255 22.0489 26.6037 22.0047 26.438 22.0047C26.205 22.0047 26.0415 22.0716 25.9464 22.2043C25.8513 22.337 25.8043 22.5302 25.8043 22.7838V23.8964H25.4858V21.7727H25.7434L25.7915 22.0791H25.8086C25.8534 22.0047 25.9101 21.941 25.9795 21.8903C26.049 21.8396 26.126 21.8007 26.2115 21.7737C26.2969 21.7468 26.3878 21.7338 26.4818 21.7338H26.4808Z"
        fill="#2C5FA1"
      />
      <path
        d="M29.5095 22.1363C29.2701 22.1363 29.0766 21.9399 29.0766 21.6982C29.0766 21.4576 29.2711 21.2612 29.5095 21.2612C29.7499 21.2612 29.9434 21.4576 29.9434 21.7004C29.9434 21.941 29.7489 22.1363 29.5095 22.1363ZM29.8782 21.6982C29.8782 21.4932 29.7147 21.3281 29.5137 21.3259C29.3075 21.3237 29.1408 21.491 29.1408 21.6982C29.1408 21.9022 29.3064 22.0705 29.5084 22.0705C29.7114 22.0705 29.8782 21.9032 29.8782 21.6982Z"
        fill="#2C5FA1"
      />
      <path
        d="M29.7211 21.9453C29.689 21.9453 29.6591 21.9453 29.6281 21.9453C29.6121 21.9453 29.6035 21.941 29.595 21.927C29.5618 21.8709 29.5266 21.8158 29.4934 21.7608C29.4913 21.7565 29.4881 21.7554 29.4838 21.7554C29.4678 21.7554 29.4518 21.7554 29.4347 21.7554V21.9453H29.3321V21.4532C29.3321 21.4532 29.3353 21.4532 29.3363 21.4532C29.3919 21.4532 29.4485 21.4532 29.5041 21.4543C29.533 21.4543 29.5618 21.4608 29.5896 21.4716C29.6366 21.491 29.6612 21.5277 29.6676 21.5773C29.6719 21.614 29.6666 21.6486 29.6441 21.6799C29.6292 21.7004 29.6099 21.7155 29.5885 21.7263C29.5853 21.7273 29.5821 21.7295 29.5779 21.7317C29.626 21.8029 29.673 21.8741 29.7211 21.9464V21.9453ZM29.4347 21.6712C29.4475 21.6712 29.4603 21.6712 29.4731 21.6712C29.4817 21.6712 29.4902 21.6712 29.4977 21.6691C29.5084 21.6669 29.5191 21.6648 29.5287 21.6604C29.5544 21.6497 29.5661 21.6291 29.565 21.5968C29.565 21.5709 29.5511 21.5525 29.5244 21.5439C29.5052 21.5374 29.4838 21.5363 29.4635 21.5363C29.4539 21.5363 29.4443 21.5363 29.4347 21.5363V21.6712Z"
        fill="#2C5FA1"
      />
    </g>
    <defs>
      <clipPath id="clip0_2230_59">
        <rect width="28" height="15" fill="white" transform="translate(2 9)" />
      </clipPath>
    </defs>
  </svg>
);
