import { SVGIconComponent } from '../types';

export const visa: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-dir="ltr" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <title>{title}</title>
    <path
      d="M30 5H2C0.89543 5 0 5.95371 0 7.13016V24.8698C0 26.0463 0.89543 27 2 27H30C31.1046 27 32 26.0463 32 24.8698V7.13016C32 5.95371 31.1046 5 30 5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5.53254C1.17157 5.53254 0.5 6.24782 0.5 7.13016V24.8698C0.5 25.7522 1.17157 26.4675 2 26.4675H30C30.8284 26.4675 31.5 25.7522 31.5 24.8698V7.13016C31.5 6.24782 30.8284 5.53254 30 5.53254H2ZM0 7.13016C0 5.95371 0.895431 5 2 5H30C31.1046 5 32 5.95371 32 7.13016V24.8698C32 26.0463 31.1046 27 30 27H2C0.895431 27 0 26.0463 0 24.8698V7.13016Z"
      fill="black"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25065 19.9375H7.08693L5.46439 13.7313C5.38738 13.4459 5.22386 13.1935 4.98333 13.0745C4.38306 12.7756 3.72159 12.5377 3 12.4177V12.1788H6.4856C6.96666 12.1788 7.32746 12.5377 7.38759 12.9545L8.22945 17.4312L10.3921 12.1788H12.4957L9.25065 19.9375ZM13.6985 19.9375H11.655L13.3377 12.1788H15.3811L13.6985 19.9375ZM18.0247 14.3283C18.0848 13.9105 18.4456 13.6715 18.8666 13.6715C19.528 13.6115 20.2486 13.7315 20.8499 14.0294L21.2107 12.3589C20.6094 12.12 19.9479 12 19.3476 12C17.3643 12 15.9211 13.0747 15.9211 14.5663C15.9211 15.7009 16.9434 16.2967 17.665 16.6557C18.4456 17.0136 18.7463 17.2525 18.6862 17.6104C18.6862 18.1472 18.0848 18.3862 17.4846 18.3862C16.763 18.3862 16.0414 18.2072 15.381 17.9083L15.0202 19.5798C15.7418 19.8777 16.5224 19.9977 17.244 19.9977C19.4679 20.0566 20.8499 18.983 20.8499 17.3714C20.8499 15.342 18.0247 15.2231 18.0247 14.3283ZM28.0015 19.9375L26.3789 12.1788H24.6361C24.2753 12.1788 23.9145 12.4177 23.7943 12.7756L20.7897 19.9375H22.8933L23.3132 18.8038H25.8979L26.1384 19.9375H28.0015ZM24.937 14.2682L25.5373 17.1924H23.8546L24.937 14.2682Z"
      fill="#1434CB"
    />
  </svg>
);
